(function () {
  const verifyEmailResendButton = document.getElementById('email_ver_but_resend');
  const verifyEmailButton = document.getElementById('email_ver_but_verify');

  if (!verifyEmailResendButton || !verifyEmailButton) {
    return
  }

  var parentNode = verifyEmailResendButton.parentNode;
  parentNode.insertBefore(verifyEmailResendButton, verifyEmailButton);
})();
