import $ from "jquery";
import * as bootstrap from "bootstrap";
import flickity from "flickity";

// Custom scripts
import './login';
import "./signup";
import "./reset_password";

// Assets
import "./scss/app.scss";

window.jQuery = $;
window.$ = $;
window.Flickity = flickity;

// Scrollspy
(function () {
  var scrollSpy = new bootstrap.ScrollSpy(document.body, {
    target: '#divein-navbar'
  })

	$("#divein-navbar .nav-link").on("click", function (event) {
		if (!this.hash || $(this).hasClass("no-trigger")) {
			return;
		}

		const navLink = this;

		event.preventDefault();
		$("html, body").animate(
			{ scrollTop: $(this.hash).offset().top - 82 },
			800,
			function () {
				navLink.blur();
			}
		);
	});
})();

// Features items
(function () {
	const featureItemsContainer = document.querySelector(
		".feature-item-container"
	);
	const crossfadeImages = document.querySelectorAll(
		".crossfade-images .crossfade-images-child"
	);
	const featureItems = !featureItemsContainer
		? null
		: document.querySelectorAll(".feature-item");

	if (!featureItemsContainer || !crossfadeImages || !featureItems) {
		return;
	}

	const activeClass = "active";
	let lastActiveFeature = featureItems[0].dataset.target;

	featureItemsContainer.addEventListener("mouseenter", initEvents);

	function initEvents() {
		clearActiveItems();

		featureItems.forEach((item) => {
			item.addEventListener("mouseenter", activateFeatureItem);
		});

		this.addEventListener("mouseleave", setLastActiveFeatureItem, {
			once: true,
		});
	}

	function clearActiveItems() {
		featureItems.forEach((x) => x.classList.remove(activeClass));
		crossfadeImages.forEach((x) => x.classList.remove(activeClass));
	}

	function setLastActiveFeatureItem() {
		const featured = Array.from(featureItems).find(
			(x) => x.dataset.target === lastActiveFeature
		);
		featured.classList.add(activeClass);

		const featuredImg = Array.from(crossfadeImages).find(
			(x) => x.id === lastActiveFeature.replace("#", "")
		);
		featuredImg.classList.add(activeClass);

		featureItems.forEach((item) => {
			item.removeEventListener("mouseenter", activateFeatureItem);
		});
	}

	function activateFeatureItem() {
		if (this.classList.contains(activeClass)) {
			return;
		}

		this.classList.add(activeClass);
		lastActiveFeature = this.dataset.target;

		const featuredImg = Array.from(crossfadeImages).find(
			(x) => x.id === lastActiveFeature.replace("#", "")
		);
		featuredImg.classList.add(activeClass);

		this.addEventListener("mouseleave", deactivateFeatureItem, { once: true });
	}

	function deactivateFeatureItem() {
		if (this.classList.contains(activeClass)) {
			this.classList.remove(activeClass);

			const featuredImg = Array.from(crossfadeImages).find(
				(x) => x.id === this.dataset.target.replace("#", "")
			);
			featuredImg.classList.remove(activeClass);
		}
	}
})();
