(function () {
	let email = document.getElementById("email");

	if (email) {
		email.focus();
	}

	let displayName = document.getElementById("displayName");
	let firstName = document.getElementById("givenName");
	let lastName = document.getElementById("surname");

	if (!displayName || !firstName || !lastName) {
		return;
	}

	const setDisplayName = () => {
		displayName.value = `${firstName.value} ${lastName.value}`;
	};

	firstName.addEventListener("keyup", setDisplayName);
	lastName.addEventListener("keyup", setDisplayName);

	//hack
	jQuery("#displayName").closest(".TextBox").addClass("azureHiddenInput");
})();

if (document.getElementById("continue")) {
	document.getElementById("continue").addEventListener("click", function () {
		if (document.getElementById("givenName").value.length < 1) {
			$("#givenName").css({ border: "1px solid red" });
		} else {
			$("#givenName").css({ border: "1px solid var(--gray)" });
		}
		if (document.getElementById("surname").value.length < 1) {
			$("#surname").css({ border: "1px solid red" });
		} else {
			$("#surname").css({ border: "1px solid var(--gray)" });
		}
	});
	// eslint-disable-next-line require-jsdoc
	function checkSecondStepFields() {
		if (
			document.getElementById("givenName").value &&
			document.getElementById("surname").value 
		) {
			$("#continue").css({ pointerEvents: "all", opacity: 1 });
		} else {
			$("#continue").css({ pointerEvents: "none", opacity: 0.5 });
		}
	}
	checkSecondStepFields();
	document.getElementById("givenName").addEventListener("input", checkSecondStepFields);
	document.getElementById("surname").addEventListener("input", checkSecondStepFields);
}

if (document.getElementById("next-step")){
	document.getElementById("next-step").addEventListener("click", function () {
		if (emailIsValid()) {
			document.getElementById("fieldIncorrect").innerHTML =
				"One or more fields are filled out incorrectly. Please check your entries and try again.";
			$("#email").css({ border: "1px solid var(--gray)" });
		} else {
			document.getElementById("fieldIncorrect").innerHTML =
				"Please enter a valid email address.";
			$("#email").css({ border: "1px solid red" });
		}
		if (document.getElementById("newPassword").value.length < 8) {
			$("#newPassword").css({ border: "1px solid red" });
		} else {
			if (
				document.getElementById("newPassword").value !=
				document.getElementById("reenterPassword").value
			) {
				$("#newPassword").css({ border: "1px solid red" });
				$("#reenterPassword").css({ border: "1px solid red" });
			} else {
				$("#newPassword").css({ border: "1px solid var(--gray)" });
				$("#reenterPassword").css({ border: "1px solid var(--gray)" });
				enableSecondStep();
			}
		}
	});
	// eslint-disable-next-line require-jsdoc
	function checkFirstStepFields() {
		if (
			document.getElementById("email").value &&
			document.getElementById("newPassword").value &&
			document.getElementById("reenterPassword").value
		) {
			$("#next-step").css({ pointerEvents: "all", opacity: 1 });
		} else {
			$("#next-step").css({ pointerEvents: "none", opacity: 0.5 });
		}
	}
	checkFirstStepFields();
	document.getElementById("email").addEventListener("input", checkFirstStepFields);
	document.getElementById("newPassword").addEventListener("input", checkFirstStepFields);
	document.getElementById("reenterPassword").addEventListener("input", checkFirstStepFields);
}

if (document.getElementById("back")){
	document
		.getElementById("back")
		.addEventListener("click", enableFirstStep)
}
// eslint-disable-next-line require-jsdoc
function enableSecondStep() {
	document.getElementById("email").style.display = "none";
	document.getElementById("newPassword").style.display = "none";
	document.getElementById("reenterPassword").style.display = "none";
	document.getElementById("givenName").style.display = "block";
	document.getElementById("surname").style.display = "block";
	document.getElementById("next-step").style.display = "none";
	document.getElementById("continue").style.display = "block";
	document.getElementById("back").style.display = "flex";
	document.getElementById("newAccount").style.display = "none";
	
	if (document.querySelectorAll('.password-toggle')) {
		document.querySelectorAll('.password-toggle').forEach(function(div) {
		div.classList.add('hide-toggle');
	  });
	}

	if (document.getElementById("continue")) {
		$("#continue").html("Next");
	}
	if (document.getElementById("first-slide")){
		$("#first-slide").addClass("inactive-slide");
	}
	if (document.getElementById("first-slide")){
		$("#second-slide").removeClass("inactive-slide");
		$("#second-slide").addClass("active-slide");
	}

}
// eslint-disable-next-line require-jsdoc
function enableFirstStep() {
	document.getElementById("email").style.display = "block";
	document.getElementById("newPassword").style.display = "block";
	document.getElementById("reenterPassword").style.display = "block";
	document.getElementById("givenName").style.display = "none";
	document.getElementById("surname").style.display = "none";
	document.getElementById("next-step").style.display = "block";
	document.getElementById("continue").style.display = "none";
	document.getElementById("back").style.display = "none";
	document.getElementById("newAccount").style.display = "flex";

	if (document.querySelectorAll('.password-toggle')) {
		document.querySelectorAll('.password-toggle').forEach(function(div) {
		div.classList.remove('hide-toggle');
	  });
	}

	if (document.getElementById("first-slide")){
		$("#first-slide").addClass("active-slide");
		$("#first-slide").removeClass("inactive-slide");
	}
	if (document.getElementById("first-slide")){
		$("#second-slide").removeClass("active-slide");
		$("#second-slide").addClass("inactive-slide");
	}
}
// eslint-disable-next-line require-jsdoc
function emailIsValid() {
	if (document.getElementById("email")) {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test($("#email").val());
	} else {
		return false;
	}
}
// eslint-disable-next-line require-jsdoc
function makePwdToggler(pwd) {
	
	// Create show-password checkbox
	const showPassword = document.createElement('img');
	showPassword.src = "https://diveinstorage.blob.core.windows.net/assets/icons/show-pass.svg";
	showPassword.classList.add('password-toggle-icon'); 

	const div = document.createElement('div');
	div.classList.add('password-toggle'); 
	div.appendChild(showPassword);  
	pwd.insertAdjacentElement('afterend', div);
	// eslint-disable-next-line require-jsdoc
	function toggle() {

	  if (pwd.type === 'password') {
		pwd.type = 'text';
	  } else {
		pwd.type = 'password';
	  }
	}
	
	showPassword.onclick = toggle;
}
  // eslint-disable-next-line require-jsdoc
  function setupPwdTogglers() {
	const pwdInputs = document.querySelectorAll('input[type=password]');
	for (let i = 0; i < pwdInputs.length; i++) {
	  makePwdToggler(pwdInputs[i]);
	}
  }
  
  setupPwdTogglers(); 
  // eslint-disable-next-line require-jsdoc
  function placeHolders(){
	if (document.getElementById("newPassword")){
		$("#newPassword").attr("placeholder", "Create Password");
	}
	if (document.getElementById("reenterPassword")){
		$("#reenterPassword").attr("placeholder", "Confirm Password");
	}
  }
  placeHolders();
