(function () {
	const B2CSignInSignUpPolicy = __B2C_SIGNIN_SIGNUP_POLICY__;
	const B2CResetPasswordPolicy = __B2C_RESET_PASSWORD_POLICY__;

	const loginButton = document.getElementById("next");

	if (loginButton) {
		loginButton.innerText = "Log in";
	}

	const redirectLink = document.getElementById("forgotPassword");

	if (redirectLink) {
		const regEx = new RegExp(B2CSignInSignUpPolicy, "ig");
		redirectLink.href = document.location.href.replace(
			regEx,
			B2CResetPasswordPolicy
		);
	}

	const azureCreateAccount = document.getElementById("createAccount");

	if (!azureCreateAccount) {
		return;
	}

	const diveInSignUp = document.getElementById("divein-signup");

	if (azureCreateAccount.href) {
		diveInSignUp.href = azureCreateAccount.href;
	} else {
		console.log(azureCreateAccount);
	}
})();
	const googleLogo = document.createElement("img");
	googleLogo.src = "https://diveinstorage.blob.core.windows.net/assets/icons/google.svg"
	googleLogo.alt = "Google Logo"

	const facebookLogo = document.createElement("img");
	facebookLogo.src = "https://diveinstorage.blob.core.windows.net/assets/icons/facebook.svg"
	facebookLogo.alt = "Facebook Logo"
	
if (document.getElementById("GoogleExchange")) {
	document.getElementById("GoogleExchange").insertAdjacentElement("afterbegin", googleLogo)
	document.getElementById("GoogleExchange").classList.add("googleButton");
}
if (document.getElementById("FacebookExchange")) {
	document.getElementById("FacebookExchange").insertAdjacentElement("afterbegin", facebookLogo);
	document.getElementById("FacebookExchange").classList.add("facebookButton");
}

if (document.getElementById("LinkedInExchange")) {
	document.getElementById("LinkedInExchange").classList.add("linkedinIcon");
	document.getElementById("LinkedInExchange").classList.add("linkedinButton");
}
if (document.getElementById("divein-login")) {
	document
		.getElementById("existing-user")
		.addEventListener("click", enableSignIn);
	document
		.getElementById("new-user")
		.addEventListener("click", enableSignUp)
}
if (document.getElementById("email-existing")){
	document
		.getElementById("email-existing")
		.addEventListener("click", enableEmailSignIn )
	}
if (document.getElementById("noAccount")){
	document
		.getElementById("noAccount")
		.addEventListener("click", enableSignUp)
	}
// eslint-disable-next-line require-jsdoc
function enableSignUp() {
	document.getElementById("localAccountForm").style.display = "none";
	document.getElementById("signupButton").style.display = "flex";
	document.getElementById("existing-user").style.display = "flex";
	document.getElementById("new-user").style.display = "none";
	document.getElementById("email-existing").style.display = "none";
	document.getElementById("GoogleExchange").style.display = "flex";
	document.getElementById("FacebookExchange").style.display = "flex";
	document.getElementById("noAccount").style.display = "none";
	document.getElementById("title").style.display = "block";

	if ($(".signup-tittle").length) {
		$(".signup-tittle").text("Sign Up");
	}

	if (document.getElementById("FacebookExchange")) {
		$("#FacebookExchange").html("Sign up with Facebook");
		$("#FacebookExchange").prepend(facebookLogo);
	}

	if (document.getElementById("GoogleExchange")) {
		$("#GoogleExchange").html("Sign up with Google");
		$("#GoogleExchange").prepend(googleLogo);
	}

	if (document.getElementById("LinkedInExchange")) {
		$("#LinkedInExchange").html("Sign up with LinkedIn");
	}
}
// eslint-disable-next-line require-jsdoc
function enableSignIn() {
	document.getElementById("localAccountForm").style.display = "none";
	document.getElementById("signupButton").style.display = "none";
	document.getElementById("existing-user").style.display = "none"
	document.getElementById("new-user").style.display= "flex"
	document.getElementById("email-existing").style.display = "flex"
	document.getElementById("GoogleExchange").style.display = "flex"
	document.getElementById("FacebookExchange").style.display = "flex"
	document.getElementById("noAccount").style.display = "none"	
	document.getElementById("title").style.display = "block";


	if ($(".signup-tittle").length) {
        $(".signup-tittle").text("Log In");
    }
	if (document.getElementById("FacebookExchange")) {
		$("#FacebookExchange").html("Continue with Facebook");
		$("#FacebookExchange").prepend(facebookLogo);
	}
	if (document.getElementById("GoogleExchange")) {
		$("#GoogleExchange").html("Continue with Google");
		$("#GoogleExchange").prepend(googleLogo);
	}
	if (document.getElementById("LinkedInExchange")) {
		$("#LinkedInExchange").html("Login with LinkedIn");
	}
	if (document.getElementById("forgotPassword")) {
		$("#forgotPassword").html("Forgot Password?");
	}
}
// eslint-disable-next-line require-jsdoc
function enableEmailSignIn() {
	document.getElementById("localAccountForm").style.display = "block";
	document.getElementById("signupButton").style.display = "none";
	document.getElementById("existing-user").style.display = "none";
	document.getElementById("new-user").style.display= "none";
	document.getElementById("title").style.display = "none";
	document.getElementById("email-existing").style.display = "none";
	document.getElementById("GoogleExchange").style.display = "none";
	document.getElementById("FacebookExchange").style.display = "none";
	document.getElementById("noAccount").style.display = "flex";
}